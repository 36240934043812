import React, { ReactNode } from "react"

import { PinkChair, WhiteBag, GoldRing, Wheel } from "./img"

interface ICard {
  titleCard: string
  descriptionCard: ReactNode
  image: JSX.Element
}

export const CARDS: ICard[] = [
  {
    titleCard: "С 30.09 –",
    descriptionCard: (
      <>
        категория «
        <a href="https://halvacard.ru/shops/avto" target="_blank" rel="noreferrer">
          Авто
        </a>
        »
      </>
    ),
    image: <Wheel alt="Руль" />,
  },
  {
    titleCard: "С 07.10 –",
    descriptionCard: (
      <>
        категории «
        <a href="https://halvacard.ru/shops/odezhda" target="_blank" rel="noreferrer">
          Одежда и&nbsp;аксессуары
        </a>
        » и «
        <a href="https://halvacard.ru/shops/obuv" target="_blank" rel="noreferrer">
          Обувь
        </a>
        »
      </>
    ),
    image: <WhiteBag alt="Белая сумка" />,
  },
  {
    titleCard: "С 14.10 –",
    descriptionCard: (
      <>
        категории «
        <a href="https://halvacard.ru/shops/tovary-dlya-remonta" target="_blank" rel="noreferrer">
          Товары для ремонта
        </a>
        » и «
        <a href="https://halvacard.ru/shops/mebel-i-interer" target="_blank" rel="noreferrer">
          Мебель и&nbsp;товары для дома
        </a>
        »
      </>
    ),
    image: <PinkChair alt="Розовый стул" />,
  },
  {
    titleCard: "С 21.10 –",
    descriptionCard: (
      <>
        категория «
        <a href="https://halvacard.ru/shops/yuvelirnye-izdeliya" target="_blank" rel="noreferrer">
          Ювелирные изделия
        </a>
        »
      </>
    ),
    image: <GoldRing alt="Золотое кольцо" />,
  },
]
