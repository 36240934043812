// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--wAsl7";
export var btnBlock = "banner-module--btnBlock--gD6cm";
export var container = "banner-module--container--TUIor";
export var hideMobBlock = "banner-module--hideMobBlock--kgvbL";
export var img = "banner-module--img--OOVGN";
export var mobBtn = "banner-module--mobBtn--VuW-6";
export var red = "banner-module--red--Dfn8w";
export var secondContainer = "banner-module--secondContainer--SCyl5";
export var section = "banner-module--section--SkUVQ";
export var someDate = "banner-module--someDate--rKCPt";
export var text = "banner-module--text--tIaTt";