// extracted by mini-css-extract-plugin
export var card = "smartWeeks-module--card--A7K1h";
export var card2 = "smartWeeks-module--card2--o9a+g";
export var card3 = "smartWeeks-module--card3--baBxz";
export var card4 = "smartWeeks-module--card4--89fX2";
export var cards = "smartWeeks-module--cards--Sg62p";
export var description = "smartWeeks-module--description--VDrBX";
export var head = "smartWeeks-module--head--VlLUp";
export var image = "smartWeeks-module--image--hjcBF";
export var section = "smartWeeks-module--section--5UyEv";
export var subText = "smartWeeks-module--subText--XDssB";
export var title = "smartWeeks-module--title--mBs8w";