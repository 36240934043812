import React, { ReactNode } from "react"

import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import { CARDS } from "./data"

import * as styles from "./smartWeeks.module.scss"

type SmartWeeksProps = {
  title?: string
  description?: ReactNode
  orderNum?: string
}

const defaultTitle = `
Умные недели с Халвой!
`

const defaultDescription = (
  <>
    Каждую неделю с 30.09 добавляем новых партнёров акции <br /> из категорий, указанных ниже
  </>
)

export function SmartWeeks({
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
}: SmartWeeksProps) {
  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.text}>
          <h2 className={styles.head}> {title} </h2>
          <div className={styles.subText}>{description} </div>
        </div>
        <div className={styles.cards}>
          {CARDS.map(({ titleCard, descriptionCard, image }, i) => (
            <div className={clsx(styles.card, styles[`card${i + 1}`])} key={i}>
              <div className={styles.title}>{titleCard}</div>
              <div className={styles.description}>{descriptionCard}</div>
              <div className={styles.image}>{image}</div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
